import { Component, EventEmitter, OnInit, ViewChild, AfterViewChecked, ChangeDetectorRef, OnDestroy, ViewChildren, QueryList } from '@angular/core'
import { combineLatest, Observable, BehaviorSubject } from 'rxjs'
import { select, Store } from '@ngrx/store'
import {
  selectAdjustLocation,
  selectAdjustLocationAddress,
  selectBreakdownCoordinates,
  selectBreakdownLocation,
  selectBreakdownLocationAddress,
  selectBreakdownLocationDetails,
  selectCurrentGeoLocation,
  selectGpsLocationDenied,
  selectHasGPSAccess,
  selectIsBreakdownLocationValid,
  selectLandMarkAndAddress,
  selectLastSearchLocation,
  selectLetsStartSheet,
  selectLocationCard,
  selectLocationMapTemplate
} from '../../location/location.selectors'
import {
  BreakdownLocation,
  BreakdownLocationDetails,
  BreakdownLocationDetailsUi,
  GenericCoordinates,
  LastSearchLocation,
  LetsStartSheet,
  LocationCard,
  LocationMapTemplate,
  MapBoundsPadding,
  MapState,
} from '../../location/location.types'
import { GoogleCoordinates, PERMISSION_STATE } from '../../location/google-geocode/types'
import {
  ensureLocationServices,
  LOCATION_TYPE,
  locationNext,
  resetBreakdownLocation,
  SET_BREAKDOWN_LOCATION,
  SET_LOCATION_CLUB,
  SET_SHOW_ADJUST_LOCATION,
  setBreakdownLocationRequest,
  setLandMark,
  setLastSearchLocation,
  setLocationDetails,
  setResetLocationDenied,
} from '../../location/location.actions'
import { LocationAddress } from '../components/breakdown-location.types'
import { debounceTime, map, tap, take } from 'rxjs/operators'
import { CITY_LEVEL_ZOOM, DEFAULT_MAX_MAP_ZOOM, DEFAULT_MIN_ZOOM } from '../../location/map/map.component'
import { LocationSearchComponent } from '../components/location-search/location-search.component'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import events from '../../tagging/events'
import { closeDialog, openMessageDialog, openPromptDialog, setFullMapBreakdownLocation } from "../../ui/ui.actions"
import { requiresGpsPrompt } from '../../location/google-geocode/google-geocode.utils'
import { MemberInfo } from "../../member/member.types"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { AdobeEventService } from "../../tagging/adobe/event-adobe.service"
import { Title } from "@angular/platform-browser"
import { haversine } from "../../../shared/haversine"
import { addPartialCallRequest } from "../../dashboard/calls.actions"
import { selectIsLoading } from "../../ui/loading/loading.selectors"
import {
  selectFullMapBreakdownLocation,
  selectIsBreakdownLocationImprovementTest,
  selectUserDefaultCoords,
  selectPreviousPageNavigation
} from "../../ui/ui.selectors"
import { selectMemberData } from "../../member/member.selectors"
import { selectIsRapUser, selectIsSecure } from "../../auth/auth.selectors"
import { SET_SERVICING_CLUB_CONFIGS } from "../../servicing-club/servicing-club.actions"
import { PaceSetterCode } from "../../issue/issue.types"
import { selectActivePaceSetterCode } from "../../issue/issue.selectors"
import { GPS_LOCATION_DISTANCE_WARN_LIMIT } from "../../location/location.constants"
import { AAAStore } from "../../../store/root-reducer"
import { TaggingService } from "../../tagging/tagging.service"
import { ConfigService } from "../../config/config.service"
import { RapService } from "../../rap/rap.service"
import { GoogleGeocodeService } from "../../location/google-geocode/google-geocode.service"
import { buildTitle } from "../../../shared/utils/title"
import { MessageDialogTypes, PromptDialogTypes } from "../../ui/ui.types"
import { isAddressComplete, isHomeAddressComplete } from "../../location/location.utils"
import { AbstractComponent } from "../../../shared/abstract.component"
import { CHECKBOX_RIPPLE_DURATION } from "../../constants/shared.constants"
import { Router, ActivatedRoute } from '@angular/router'

export enum LocationModalState {
  ADJUST_LOCATION = 1,
  LOCATION_DETAILS = 2,
  SEARCH_LOCATION = 3
}

const TITLE_BREAKDOWN_LOCATION = () => $localize`Set Breakdown Location`
const ZOOM_CHANGE_DELAY = 2300

const LOCATION_DETAILS_MODAL_LOCATOR = 'ion-modal.location-details-modal > div'
const MAP_BOUNDS_TOP_OFFSET = 75

@Component({
  selector: 'app-breakdown-location-step',
  templateUrl: './breakdown-location-step.component.html',
  styleUrls: ['./breakdown-location-step.component.scss'],
})
export class BreakdownLocationStepComponent extends AbstractComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChild('locationSearch') locationSearch: LocationSearchComponent

  breakdownLocationLandMark$: Observable<string> = this.store$.pipe(
    select(selectLandMarkAndAddress)
  )
  isBreakdownLocationValid$: Observable<boolean> = this.store$.pipe(
    select(selectIsBreakdownLocationValid)
  )
  breakdownLocationAddress$: Observable<string> = this.store$.pipe(
    select(selectBreakdownLocationAddress)
  )
  breakdownLocation$: Observable<BreakdownLocation> = this.store$.pipe(
    select(selectBreakdownLocation)
  )
  isFullMapBreakdownLocation$: Observable<boolean> = this.store$.pipe(
    select(selectFullMapBreakdownLocation)
  )

  member$: Observable<MemberInfo> = this.store$.pipe(select(selectMemberData))
  member: MemberInfo

  isSecure$: Observable<boolean> = this.store$.pipe(select(selectIsSecure))

  isSetBreakdownLocationLoading$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(SET_BREAKDOWN_LOCATION.ACTION))
  )

  userCoordsByParams$ = this.store$.pipe(select(selectUserDefaultCoords))

  userCoordsByGPS$: Observable<GenericCoordinates> = this.store$.pipe(
    select(selectCurrentGeoLocation)
  )

  lastSearchLocation$: Observable<LastSearchLocation> = this.store$.pipe(
    select(selectLastSearchLocation)
  )

  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))

  modalIndex$ = this.route.queryParams.pipe(
    map(params => Number(params['index']))
  )

  isFullMap
  isBreakdownLocationValid
  showFullMap$ = combineLatest([
    this.isFullMapBreakdownLocation$,
    this.isBreakdownLocationValid$,
    this.userCoordsByParams$,
    this.isSecure$,
  ]).pipe(map( ([isFullMapBreakdownLocation, isBreakdownLocationValid, userCoordsByParams, isSecure]) => {
    this.isFullMap = isFullMapBreakdownLocation && !isBreakdownLocationValid && !isSecure && !userCoordsByParams?.lat && !userCoordsByParams?.lng
    this.isBreakdownLocationValid = isBreakdownLocationValid
    this.sendPageLoadEvent()
    return this.isFullMap
  }))

  gpsCoordinates
  userCoords$ = combineLatest([
    this.userCoordsByGPS$,
    this.userCoordsByParams$,
  ]).pipe(
    map(([userCoordsByGPS, userCoordsByParams]) => {
      this.gpsCoordinates = userCoordsByGPS
      const _coords = userCoordsByGPS || userCoordsByParams
      this.gpsCoordinates = _coords
      return {
        ..._coords,
        accuracy: userCoordsByGPS?.accuracy || userCoordsByParams?.accuracy
      }
    })
  )

  isServicingClubConfigLoading$ = combineLatest([
    this.store$.pipe(select(selectIsLoading(SET_LOCATION_CLUB.ACTION))),
    this.store$.pipe(
      select(selectIsLoading(SET_SERVICING_CLUB_CONFIGS.ACTION))
    ),
  ]).pipe(map((areActionsLoading) => areActionsLoading.find(Boolean)))

  activePaceSetterCode$: Observable<PaceSetterCode> = this.store$.pipe(select(selectActivePaceSetterCode))
  hasGPSAccess$: Observable<boolean> = this.store$.pipe(select(selectHasGPSAccess))
  hasDeniedGpsAccess$: Observable<boolean> = this.store$.pipe(select(selectGpsLocationDenied))

  locationCard$: Observable<LocationCard> = this.store$.pipe(
    select(selectLocationCard),
  )

  letsStartSheet$: Observable<LetsStartSheet> = this.store$.pipe(
    select(selectLetsStartSheet)
  )

  locationMap$: Observable<LocationMapTemplate> = this.store$.pipe(
    select(selectLocationMapTemplate)
  ).pipe(tap((locationMapTemplate: LocationMapTemplate) => {
    if (this.isBreakdownLocationImprovementTest) {
      const validLocationResolved = !!this.isValid && !!locationMapTemplate.centerMarker
      validLocationResolved ? this.openAdjustLocationModal() : this.openLetsStartModal()
    }
  }))

  breakdownCoordinates$: Observable<GoogleCoordinates> = this.store$.pipe(
    select(selectBreakdownCoordinates),
  )

  selectAdjustLocationAddress$: Observable<LocationAddress> = this.store$.pipe(
    select(selectAdjustLocationAddress)
  )

  selectAdjustLocation$: Observable<boolean> = this.store$.pipe(
    select(selectAdjustLocation)
  )

  isBreakdownLocationImprovementTest = false
  isBreakdownLocationImprovementTest$: Observable<boolean> = this.store$.pipe(
    select(selectIsBreakdownLocationImprovementTest),
    tap((isBreakdownLocationImprovementTest: boolean) => {
      this.isBreakdownLocationImprovementTest = isBreakdownLocationImprovementTest
    })
  )

  zoomLevel$ = combineLatest([
    this.isBreakdownLocationValid$,
    this.selectAdjustLocation$,
  ]).pipe(
    debounceTime(ZOOM_CHANGE_DELAY),
    map(([isValid, isAdjust]) => {
      let zoom = DEFAULT_MIN_ZOOM
      if (isAdjust) {
        zoom = CITY_LEVEL_ZOOM
      } else if (isValid) {
        zoom = DEFAULT_MAX_MAP_ZOOM
      }
      return zoom
    })
  )

  isLocationDetailsDisplay = false
  permissionState: any = false
  selectedAddress: string
  isValid = false
  isLoading = false
  hasCoordinates = false
  hasDetails = false
  breakdownLocation: BreakdownLocation = null

  details: BreakdownLocationDetails = {
    options: [],
    notes: '',
  }

  isHomeAddressComplete: Boolean = false
  paceSetterCode: PaceSetterCode
  hasGPSAccess: boolean

  showAdjustLocation: Boolean = false
  showAdjustLocationNeedMoreInfo$: EventEmitter<Boolean> = new EventEmitter<Boolean>()

  detailsSheetHeight = 0

  mapPadding = new BehaviorSubject<MapBoundsPadding>({ left: 0, top: 0, right: 0, bottom: 0 })
  mapPadding$ = this.mapPadding.asObservable()
  previousDisplayState = false
  isLocationDetailsWait = false

  ngAfterViewChecked() {
    if (this.isBreakdownLocationImprovementTest) {
      this.modalIndex$.pipe(take(1)).subscribe(modalIndex => {
        const isLocationDetails = modalIndex === LocationModalState.LOCATION_DETAILS
        if (isLocationDetails) {
          const element = document.querySelector(LOCATION_DETAILS_MODAL_LOCATOR)
          if (element) {
            const currentHeight = (element as HTMLElement).getBoundingClientRect().height
            if (currentHeight !== this.detailsSheetHeight) {
              this.detailsSheetHeight = currentHeight
              this.mapPadding.next({
                left: 0,
                right: 0,
                top: 0,
                bottom: currentHeight - MAP_BOUNDS_TOP_OFFSET
              })
              this.cdr.detectChanges()
            }
          }
        } else if (this.previousDisplayState !== isLocationDetails) {
          this.detailsSheetHeight = 0
          this.mapPadding.next({
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          })
          this.cdr.detectChanges()
        }

        this.previousDisplayState = isLocationDetails
      })
    }
  }

  constructor(
    private actions$: Actions,
    protected store$: Store<AAAStore>,
    protected tagging: TaggingService,
    protected adobeEventService: AdobeEventService,
    protected configService: ConfigService,
    private titleService: Title,
    private rapService: RapService,
    protected locationService: GoogleGeocodeService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super()
    this.titleService.setTitle(buildTitle(TITLE_BREAKDOWN_LOCATION(), this.rapService.isRapUser()))
    this.tagging.setPageEvent(
      events.location.LOCATION_PAGE_PROMPT,
      events.location.LOCATION_PAGE_TYPE
    )
  }

  setShowAdjustLocation$ = createEffect(() => this.actions$.pipe(ofType(SET_SHOW_ADJUST_LOCATION)), { dispatch: false })

  addressCleared = false
  handleAddressSelected(address) {
    this.addressCleared = !Object.keys(address).length
    this.store$.dispatch(resetBreakdownLocation())
    if (this.addressCleared) {
      return
    }

    if (address.description) {
      this.store$.dispatch(
        setBreakdownLocationRequest({
          payload: address.description,
          meta: { locationType: LOCATION_TYPE.ADDRESS_INPUT },
        })
      )
      this.adobeEventService.sendEvent({
        eventValue: events.location.LOCATION_SEARCH_CLICK,
        eventName: AdobeEventTypes.CTA
      })

      this.tagging.setClickEvent(
        events.location.LOCATION_SEARCH_CLICK,
        events.location.LOCATION_PAGE_TYPE
      )
    }

    if (address?.landmark) {
      this.store$.dispatch(
        setLandMark({
          payload: address?.landmark || '',
        })
      )
    }

    if (address?.main_text) {
      this.store$.dispatch(setLastSearchLocation({ payload: address }))
    }
  }

  handleOptionalDetailsChanged(event: BreakdownLocationDetailsUi) {
    const selected = event.options.length
    const options = (selected ? event.options : this.details.options || []).map((opt) => opt.name)?.join(', ')
    // not expected to have no option been selected or deselecting when none is selected, anyways just in case
    if (!options) {
      return
    }

    if (!event.checked) {
      this.storeOptionalDetails({})
    } else {
      this.storeOptionalDetails({ ...event })
    }
    const _action = `${events.location.LOCATION_DETAILS_SELECT}: ${options}${event.checked ? '' : ' UNSELECTED'}`
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: _action
    })
    this.tagging.setClickEvent(_action, events.location.LOCATION_PAGE_TYPE)
  }

  useHomeLocation(member: MemberInfo) {
    this.store$.dispatch(resetBreakdownLocation())
    this.store$.dispatch(
      setBreakdownLocationRequest({
        payload: { member },
        meta: { locationType: LOCATION_TYPE.HOME },
      })
    )

    this.tagging.setClickEvent(
      events.location.LOCATION_HOME_CLICK,
      events.location.LOCATION_PAGE_TYPE
    )

    if (this.isBreakdownLocationImprovementTest) {
      this.openAdjustLocationModal()
    }
  }

  useCurrentLocation({ isFindMyLocationClick = false, isLocateUserFromMapClick = false } = {}) {
    this.store$.dispatch(resetBreakdownLocation())
    this.store$.dispatch(
      setBreakdownLocationRequest({
        payload: this.gpsCoordinates,
        meta: { locationType: LOCATION_TYPE.GPS_LOCATION },
      })
    )

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: isFindMyLocationClick
        ? events.location.LOCATION_FIND_MY_LOCATION
        : isLocateUserFromMapClick
          ? events.location.LOCATE_ME
          : events.location.LOCATION_CURRENT_CLICK
    })

    this.tagging.setClickEvent(
      isLocateUserFromMapClick ? events.location.LOCATE_ME : events.location.LOCATION_CURRENT_CLICK,
      events.location.LOCATION_PAGE_TYPE
    )

    this.openLetsStartModal()
  }

  checkBreakdownLocationDistance() {
    if (
      this.gpsCoordinates &&
      (
        this.breakdownLocation?.locationType === LOCATION_TYPE.PIN_DROP ||
        this.breakdownLocation?.locationType === LOCATION_TYPE.ADDRESS_INPUT
      )
    ) {
      const gpsToLocationDistance = haversine({
        latitude: Number(this.breakdownLocation.latitude),
        longitude: Number(this.breakdownLocation.longitude),
      },
      {
        latitude: Number(this.gpsCoordinates.lat),
        longitude: Number(this.gpsCoordinates.lng),
      },
      { unit: 'mile' })

      if (gpsToLocationDistance > GPS_LOCATION_DISTANCE_WARN_LIMIT) {
        return this.store$.dispatch(openMessageDialog({
          payload: {
            type: MessageDialogTypes.GPS_LOCATION_DISTANCE_WARNING,
            disableClose: true,
            submit: () => this.onNext()
          },
        }))
      }
    }

    this.onNext()
  }

  onNext() {
    if (this.isValid && this.hasCoordinates) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.location.LOCATION_NEXT_CLICK,
      })
      this.tagging.setClickEvent(
        events.location.LOCATION_NEXT_CLICK,
        events.location.LOCATION_PAGE_TYPE
      )

      if (!isAddressComplete(this.breakdownLocation) && !this.hasDetails) {
        return this.store$.dispatch(
          openPromptDialog({
            payload: {
              type: PromptDialogTypes.ADDITIONAL_LOCATION_INFO,
            },
          })
        )
      } else {
        this.store$.dispatch(locationNext())
        this.store$.dispatch(addPartialCallRequest())
      }
    }
  }

  storeOptionalDetails(details) {
    this.store$.dispatch(
      setLocationDetails({
        payload: { ...details },
      })
    )
  }

  adjustLocation() {
    this.showAdjustLocation = true
    this.showAdjustLocationNeedMoreInfo$.emit(false)
    this.tagging.setPageLoadEvent({ pageType: events.location.PAGE_TYPE, pageName: events.location.PAGE_NAME_ADJUST_LOCATION })
  }

  sendPageLoadEvent() {
    let pageName = this.isFullMap ? events.location.PAGE_NAME_GPS_INSTRUCTION : events.location.PAGE_NAME_LOCATION_DEFAULT
    if (!this.isFullMap && this.isBreakdownLocationValid) {
      pageName = events.location.PAGE_NAME_CONFIRM_ADDRESS
    }
    if (!this.addressCleared) {
      this.tagging.setPageLoadEvent({ pageType: events.location.PAGE_TYPE, pageName })
    }
    this.addressCleared = false
  }

  inputCleared() {
    this.selectedAddress = null
    this.addressCleared = true
  }

  ngOnInit() {
    this.store$.dispatch(setResetLocationDenied())
    this.subscriptions.push(
      this.breakdownLocationLandMark$.subscribe((address) => {
        this.selectedAddress = address
        this.isValid = address && address !== ''
      }),
      this.breakdownLocationAddress$.subscribe((address) => {
        this.selectedAddress = address
        this.isValid = address && address !== ''
      }),
      this.breakdownLocation$.subscribe((location) => {
        this.breakdownLocation = location
        this.hasCoordinates = !!(
          location &&
          location.latitude &&
          location.longitude
        )
      }),
      this.member$.subscribe((member: MemberInfo) => {
        this.isHomeAddressComplete = isHomeAddressComplete(member)
        this.member = member
      }),
      this.store$
        .pipe(select(selectBreakdownLocationDetails))
        .pipe(debounceTime(CHECKBOX_RIPPLE_DURATION))
        .subscribe((details) => {
          this.details = details
          this.hasDetails =
            details &&
            ((details.notes && details.notes.length > 0) ||
              details.options.length > 0)
        }),
      this.hasGPSAccess$.subscribe((hasGPSAccess) => {
        this.hasGPSAccess = hasGPSAccess
        if (hasGPSAccess) {
          this.store$.dispatch(
            closeDialog({
              payload: { type: MessageDialogTypes.LOCATION_SERVICES_REQUIRED },
            })
          )
        }
      }),
      this.isSetBreakdownLocationLoading$.subscribe((isLoading) => {
        // fix for concurrency issues after changing the location and confirming
        // it adds a 2s delay in the confirmation after the location is changed
        if (this.isBreakdownLocationImprovementTest && isLoading === false) {
          this.isLocationDetailsWait = true
          setTimeout(() => {
            this.isLocationDetailsWait = false
          }, 2000)
        }
        this.isLoading = isLoading
      }),
      this.activePaceSetterCode$.subscribe((paceSetterCode) => this.paceSetterCode = paceSetterCode),
      this.setShowAdjustLocation$.subscribe(({ payload: { isAddressComplete: _isAddressComplete } }) => {
        this.showAdjustLocation = !_isAddressComplete
        this.showAdjustLocationNeedMoreInfo$.emit(!_isAddressComplete)
      })
    )
    this.locationService.getGeolocationPermission().then((state: PERMISSION_STATE) => {
      this.permissionState = state
      if (!this.isBreakdownLocationValid
        && !this.configService.getConfig().nativeAppView
        && !requiresGpsPrompt(this.permissionState)) {

        this.permissionState === PERMISSION_STATE.GRANTED && !this.isValid
          ? this.useCurrentLocation()
          : this.store$.dispatch(ensureLocationServices())
      } else if (this.isBreakdownLocationImprovementTest) {
        this.openLetsStartModal()
      }
    })
  }

  handleLocationSelected(location: LocationAddress) {
    delete location.icon
    this.handleAddressSelected(location)
    if (this.isBreakdownLocationImprovementTest) {
      this.openAdjustLocationModal()
    }
  }

  handleEnterYourAddressClick(clear?: boolean) {
    if (clear) {
      this.inputCleared()
    }
    if (this.isBreakdownLocationImprovementTest) {
      this.openSearchLocationModal()
    } else {
      this.locationSearch.focus()
    }
  }

  handleLocationSearchClose() {
    this.store$.dispatch(setFullMapBreakdownLocation({ payload: true }))
    if (this.isBreakdownLocationImprovementTest) {
      this.openLetsStartModal()
    }
  }

  handleAdjustLocationClick() {
    if (!this.isBreakdownLocationImprovementTest) {
      this.adjustLocation()
    } else {
      this.openAdjustLocationModal()
    }
  }

  turnOffLetsStartSheet() {
    this.store$.dispatch(setFullMapBreakdownLocation({ payload: false }))
  }

  handleMapDrag(mapState: MapState) {
    this.store$.dispatch(
      setBreakdownLocationRequest({
        payload: {
          lat: Number(mapState.center.latitude),
          lng: Number(mapState.center.longitude),
        },
        meta: { locationType: LOCATION_TYPE.PIN_DROP },
      })
    )
  }

  handleLocationConfirm(displayDetails?: boolean) {
    displayDetails && !this.isBreakdownLocationImprovementTest
      ? this.isLocationDetailsDisplay = displayDetails
      : this.openLocationDetailsModal()

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.location.LOCATION_DROP_PIN
    })
    this.tagging.setClickEvent(
      events.location.LOCATION_DROP_PIN,
      events.location.LOCATION_PAGE_TYPE
    )
  }

  handleNext() {
    this.isLocationDetailsDisplay = false
    this.checkBreakdownLocationDistance()
  }

  canPromptLocationPermissions() {
    return !this.configService.getConfig().nativeAppView && requiresGpsPrompt(this.permissionState)
  }

  private openModal(index: number) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { index: index.toString() },
        queryParamsHandling: 'merge',
        replaceUrl: true
      }
    )
  }

  navigateBack() {
    this.store$.pipe(
      select(selectPreviousPageNavigation),
      take(1)
    ).subscribe(({ url }) => {
      if (url) {
        this.router.navigateByUrl(url)
      }
    })
  }

  openLetsStartModal() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { index: null },
        queryParamsHandling: 'merge'
      }
    );
  }

  openAdjustLocationModal() {
    this.openModal(LocationModalState.ADJUST_LOCATION)
  }

  openSearchLocationModal() {
    this.openModal(LocationModalState.SEARCH_LOCATION)
  }

  openLocationDetailsModal() {
    this.openModal(LocationModalState.LOCATION_DETAILS)
  }

  protected readonly LocationModalState = LocationModalState
}
