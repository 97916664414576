<aaa-modal
  i18n-title
  [isOpen]="isOpen"
  [initialBreakpoint]="1"
  [breakpoints]="canDismiss ? [0,1] : [1]"
  [backdropDismiss]="canDismiss"
  contentMode="fit"
  title="Let’s Find You and Your Vehicle"
  (aaaBreakpointDidChange)="setCurrentBreakpoint($event)"
  (aaaModalWillDismiss)="handleWillDismiss($event)"
  [handle]="false"
  customClass="lets-start-modal"
>
  <div class="modal-container">
    @if (showFindMyLocation && isGpsStatePrompt()) {
      <section (click)="handleFindMyLocation()">
        <aaa-text align="center" i18n
        >Please, click Allow when the browser prompts for your current
          location.</aaa-text
        >

        <div *ngIf="browserImage">
          <figure [ngClass]="browserImage.cssClass">
            <img [src]="browserImage.image" i18n-alt alt="Service Location" />

            <div class="border-hand">
              <icon-hand></icon-hand>
            </div>
          </figure>
        </div>

        <aaa-text
          class="ion-margin-bottom ion-margin-top"
          size="body"
          color="subtle"
          align="center"
          i18n
        >It's the fast way to access your precise location.</aaa-text>
      </section>
    } @else if (homeAddress) {
      <aaa-text
        class="ion-margin-bottom ion-margin-top"
        size="body"
        color="subtle"
        align="center"
        i18n
      >Please use your home address or enter your breakdown location.</aaa-text>
    } @else {
      <aaa-text
        class="ion-margin-bottom ion-margin-top"
        size="body"
        color="subtle"
        align="center"
        i18n
      >Please enter your breakdown location.</aaa-text>
    }
    <footer>
      <ion-grid class="ion-no-margin">
        <ion-row *ngIf="showFindMyLocation">
          <ion-col>
            <aaa-button
              variant="yellow"
              id="find-my-location"
              expand="block"
              size="large"
              (click)="handleFindMyLocation()"
              [loading]="isLoading"
              [disabled]="hasDeniedGpsAccess || isLoading"
              appCtaClickEvent="Find My Location"
              type="submit"
            >
              <aaa-icon name="current-location"></aaa-icon>
              <ng-container i18n>Find My Location</ng-container>
            </aaa-button>
          </ion-col>
        </ion-row>
        <ion-row [ngClass]="{'ion-padding-top': showFindMyLocation }">
          <ion-col>
            <aaa-button
              expand="block"
              [variant]="showFindMyLocation ? 'secondary' : 'yellow'"
              size="large"
              (click)="handleEnterYourAddress()"
              appCtaClickEvent="Enter Your Address"
            >
              <aaa-icon name="search-outline"></aaa-icon>
              <ng-container i18n>Enter My Address</ng-container>
            </aaa-button>
          </ion-col>
        </ion-row>
        @if (homeAddress) {
          <ion-row class="ion-padding-top">
            <ion-col>
              <aaa-button
                expand="block"
                variant="ghost"
                size="large"
                (click)="handleHomeAddressClick()"
                appCtaClickEvent="Enter My Home Address"
              >
                <ion-grid class="ion-no-padding">
                  <ion-row class="ion-align-items-center ion-justify-content-center">
                    <ion-col>
                      <aaa-icon name="home"></aaa-icon>
                      <span i18n class="margin-left-8 use-home-address-text">Use My Home Address</span>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <aaa-text size="caption" weight="regular" class="ellipsis-text">{{homeAddress}}</aaa-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </aaa-button>
            </ion-col>
          </ion-row>
        }
      </ion-grid>
    </footer>
  </div>
</aaa-modal>
